import React from 'react';
import { Box, Grid, makeStyles, Typography, lighten } from '@material-ui/core';

import { Meta } from '../components/Meta';
import { Layout } from '../components/Layout';
import { HeroSection } from '../components/HeroSection';
import { Schedule } from '../components/Schedule';
import { phoneNumbers } from '../common/constants';
import { primaryColor } from '../gatsby-theme-material-ui-top-layout/theme';

import HeroImage from '../images/contactHero.webp';

const heroTitle = 'Contact Us';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '7% 15%',
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: 300,
    textAlign: 'left',
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
  },
  subtitle: {
    fontWeight: 400,
    textAlign: 'left',
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
  },
  cta: {
    color: 'white',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: 48,
    padding: '0.5em 0.6em',
    borderRadius: '0.8em',
    backgroundColor: primaryColor,
    marginTop: '1em',
    marginBottom: '1.5em',
    '&:hover': {
      backgroundColor: lighten(primaryColor, 0.1),
    },
    '&:active': {
      backgroundColor: lighten(primaryColor, 0.2),
    },
    '@media (max-width: 767px)': {
      fontSize: 25,
    },
  },
}));

export default function ContactPage() {
  const classes = useStyles();
  return (
    <Layout>
      <Meta route="contact" />
      <HeroSection title={heroTitle} image={HeroImage} />
      <Grid container spacing={4} className={classes.container}>
        <Grid item md={8} xs={12}>
          <Typography variant="h2" className={classes.title}>
            Servicing Nantucket Island
          </Typography>
          <Box mt={3} />
          <Typography variant="h5" color="textSecondary" className={classes.subtitle}>
            Do you have anything in your mind to tell us?
            <br />
            Have a question or would like to receive a quote?
            <br />
            Please give us a call.
          </Typography>
          <a className={classes.cta} href={phoneNumbers.machineReadable}>
            {phoneNumbers.humanReadable}
          </a>
        </Grid>
        <Grid item md={4} xs={12}>
          <Schedule />
        </Grid>
      </Grid>
    </Layout>
  );
}
