import * as React from 'react';
import { makeStyles } from '@material-ui/core';

const schedule = [
  {
    day: 'Monday',
    hours: '8:00 AM - 5:00 PM',
  },
  {
    day: 'Tuesday',
    hours: '8:00 AM - 5:00 PM',
  },
  {
    day: 'Wednesday',
    hours: '8:00 AM - 5:00 PM',
  },
  {
    day: 'Thursday',
    hours: '8:00 AM - 5:00 PM',
  },
  {
    day: 'Friday',
    hours: '8:00 AM - 5:00 PM',
  },
  {
    day: 'Saturday',
    hours: '8:00 AM - 5:00 PM',
  },
  {
    day: 'Sunday',
    hours: 'CLOSED',
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      alignItems: 'center',
    },
  },
  header: {
    marginTop: 0,
    marginBottom: 35,
    fontSize: 40,
    fontWeight: 500,
    '@media (max-width: 767px)': {
      marginBottom: 20,
      fontSize: 32,
      textAlign: 'center',
    },
  },
  schedule: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    columnGap: 90,
    '@media (max-width: 767px)': {
      columnGap: 70,
    },
  },
  body: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 2.4,
    '@media (max-width: 1199px)': {
      fontSize: 16,
    },
    '@media (max-width: 767px)': {
      fontSize: 11,
    },
  },
}));

export function Schedule() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2 className={classes.header}>Hours of Operation</h2>
      <div className={classes.schedule}>
        {schedule.map((item, idx) => (
          <React.Fragment key={idx}>
            <div className={classes.body}>{item.day}</div>
            <div className={classes.body}>{item.hours}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
